import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service";
export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "sponsors",
  data() {
    return {
      requiredRule: [v => !!v || "Field is required"],
      isSaving: false,
      sponsorId: null,
      valid: false,
      citiesSearchInput: null,
      sponsor: {
        title: "",
        base64Image: "",
        logo: "",
        active: false,
        cityIds: [],
        type: "",
        allCities: false
      },
      tabIndex: 0,
      cities: [],
      search: "",
      citiesIsLoading: false,
      types: ["CITY_NEWS", "SHOP_NEWS", "OTHER"]
    };
  },
  created() {
    this.sponsorId = this.$route.params.sponsorId;
    let title = this.sponsorId != null ? "Sponsor details" : "Insert new sponsor";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: title }]);
    if (this.sponsorId != null) {
      ApiService.get(`api/blog`, `${this.sponsorId}`)
        .then(response => {
          this.$log.debug("Sponsor: ", response.data);
          this.sponsor = response.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
    this.searchCities();
  },
  methods: {
    previousState() {
      this.$router.go(-1);
    },
    endsWith(suffix, str) {
      return str.indexOf(suffix, str.length - suffix.length) !== -1;
    },
    paddingSize(value) {
      if (this.endsWith("==", value)) {
        return 2;
      }
      if (this.endsWith("=", value)) {
        return 1;
      }
      return 0;
    },
    size(value) {
      return (value.length / 4) * 3 - this.paddingSize(value);
    },
    byteSize(base64String) {
      return this.formatAsBytes(this.size(base64String));
    },
    formatAsBytes(size) {
      return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " bytes";
    },
    handleImage(event) {
      if (event && event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        // this.toBase64(file);
        this.toBase64(file, base64Data => {
          this.sponsor.base64Image = base64Data;
        });
      }
    },
    setFileData(event, entity, field, isImage) {
      if (event && event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        if (isImage && !/^image\//.test(file.type)) {
          return;
        }
        // this.toBase64(file);
        this.toBase64(file, base64Data => {
          entity[field] = base64Data;
        });
      }
    },
    createBase64Image(fileObject) {
      const fileReader = new FileReader();
      fileReader.onload = e => {
        this.sponsor.image = e.target.result;
      };
      fileReader.readAsDataURL(fileObject);
    },
    toBase64(file, cb) {
      const fileReader = new FileReader();
      fileReader.onload = e => {
        const base64Data = e.target.result;
        cb(base64Data);
      };
      fileReader.readAsDataURL(file);
    },
    clearInputImage(field) {
      this.sponsor[field] = null;
    },
    remove(item) {
      const index = this.sponsor.cityIds.indexOf(item.id);
      if (index >= 0) this.sponsor.cityIds.splice(index, 1);
    },
    searchCities() {
      // Items have already been loaded
      if (this.cities.length > 0) return;

      // Items have already been requested
      if (this.citiesIsLoading) return;

      this.citiesIsLoading = true;
      ApiService.query("api/cities")
        .then(res => {
          this.cities = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.citiesIsLoading = false));
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
    validateAndSave() {
      if (this.$refs.form.validate()) {
        this.saveBlog(this.sponsor);
      }
    },
    saveBlog(sponsor) {
      this.isSaving = true;
      this.loading = true;
      if (sponsor.id != null) {
        ApiService.put(`api/blog`, sponsor)
          .then(response => {
            this.$log.debug("Sponsor created: " + response.id);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.isSaving = false;
            this.loading = false;
            this.previousState();
          });
      } else {
        ApiService.post(`api/blog`, sponsor)
          .then(response => {
            this.$log.debug("Sponsor created: " + response);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.isSaving = false;
            this.loading = false;
            this.previousState();
          });
      }
    },
    handleSelectAll(val) {
      this.sponsor.cityIds = val ? this.cities?.map(item => item.id) : [];
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    ...mapGetters(["currentUser"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("input", value);
        }
      }
    }
  }
};
